import { ReactComponent as ChatIcon } from '../icons/chat_icon.svg'
import { ReactComponent as CloseIcon } from '../icons/close_icon.svg'

import { useBuck } from '../BuckContext'

import styles from '../Style.module.css'

function Header(props) {
    const { setIsOpen } = props
    const { configuration } = useBuck()


    const handleClose = event => {
        event.preventDefault()

        setIsOpen(false)
    }

    return (
        <section className={styles.header}>
            <ChatIcon className={styles.header_chatIcon} width="22" height="22" />
            <span className={styles.header_title}>{configuration.messages.title}</span>
            <CloseIcon className={styles.header_closeIcon} onClick={handleClose} width="22" height="22" />
        </section>
    )
}

export default Header
