import { useEffect, useRef } from 'react'
import { useBuck } from '../BuckContext.js'
import { mergeClasses } from '../Utils.js';

import styles from '../Style.module.css'

export default function ListMessages() {
    const bottomRef = useRef(null)
    const { messages } = useBuck()

    useEffect(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'end'})
    }, [messages])

    return (
        <>
            <ul className={styles.listMessages}>
                {messages.map(message => (
                    <li key={message.id} className={mergeClasses(styles.message, styles[message.from])}>
                        {message.text}
                    </li>
                ))}
                <div ref={bottomRef} />
            </ul>
        </>
    )
}

