import axios from "axios"

export const mergeClasses = (...args) => [...new Set(args)].join(' ')

export const getLocalStorage = key => {
    let memo = localStorage.getItem('buck')

    if (memo === null) memo = {}
    else memo = JSON.parse(memo)

    if (key in memo) return memo[key]

    return null
}

export const setLocalStorage = (key, value) => {
    let memo = localStorage.getItem('buck')
    if (memo === null) memo = {}
    else memo = JSON.parse(memo)

    const merged = { ...memo, [key]: value }
    localStorage
        .setItem('buck', JSON.stringify(merged))
}

const httpClient = axios.create({
    // baseURL: 'http://localhost:3000/api/web_chat/',
    baseURL: 'https://triage-ai.buckplatform.com/',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

export const httpPost = (path, data, access_key) => {
    return httpClient.post(path, data, { headers: { 'X-ACCESS-KEY': access_key } })
}

export const httpPut = (path, data, access_key) => {
    return httpClient.put(path, data, { headers: { 'X-ACCESS-KEY': access_key } })
}

export const httpGet = (path, access_key) => {
    return httpClient.get(path, { headers: { 'X-ACCESS-KEY': access_key } })
}
