import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
// import { httpGet, getLocalStorage, setLocalStorage } from './Utils'
import { httpGet, getLocalStorage } from './Utils'
import { BuckProvider } from './BuckContext'

const initApp = (configuration, conversation) => {
  const buckElement = document.createElement('div')
  buckElement.id = 'buckElement'
  document.body.appendChild(buckElement)

  const root = ReactDOM.createRoot(document.getElementById('buckElement'))

  root.render(
    <React.StrictMode>
      <BuckProvider initialState={{ configuration, conversation, messages: [] }}>
        <App />
      </BuckProvider>
    </React.StrictMode>
  )
}

const checkConversation = (response, access_key) => {
  const conversationId = getLocalStorage('conversation')
  const configuration = { ...response.data, access_key }
  if (conversationId === null) return initApp(configuration, conversationId)

  const conversationExist = res => {
    res.data.status === 'in_conversation' ? initApp(configuration, conversationId) : initApp(configuration, null)
  }

  const conversationNotExist = err => {
    console.log(err, conversationId)
    initApp(configuration, null)
    // setLocalStorage('conversation', null)
  }

  httpGet(`/omnichannel/conversations/${conversationId}`, access_key)
    .then(conversationExist)
    .catch(conversationNotExist)
}

window.initBuck = async access_key => {
  const BUCK_ELEMENT_ID = 'buckElement'
  if (document.getElementById(BUCK_ELEMENT_ID) !== null) return;

  httpGet('/authenticate', access_key)
    .then(response => checkConversation(response, access_key))
    .catch(err => console.log(err))
}


initApp({
  "theme": {
      "colors": {
          "primary": "#009688",
          "secondary": "#EEE",
          "primary_text": "#FFFFFF",
          "secondary_text": "#212121"
      },
      "position": {
          "top": "initial",
          "left": "initial",
          "right": "15px",
          "bottom": "15px"
      },
      "width": "475px",
      "max_height": "600px",
      "background_color": "#FFFFFF"
  },
  "google_api_key": "X",
  "messages": {
      "title": "Buck.ai TradesGPT",
      "customer_information_welcome_message": "Kim olduğunuzu tanıyabilmemiz için lütfen aşağıda ki bilgileri doldurunuz",
      "conversation_create_message": "Hoş geldiniz sayın %{customer}, lütfen sorununuzu anlatınız",
      "already_have_conversation": "Hali hazırda aktif bir conversationınız bulunuyor devam etmek ister misiniz?"
  }
}, new Date().getTime())
