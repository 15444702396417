import { useBuck } from '../BuckContext'
import UnknownConversationForm from './UnknownConversationForm'
import KnownConversationForm from './KnownConversationForm'

function ConversationInformationForm(props) {
    const { setStep } = props
    const { conversation } = useBuck()

    return conversation === null ? <UnknownConversationForm setStep={setStep} /> : <KnownConversationForm />
}

export default ConversationInformationForm
