import Header from '../components/Header'
import InteractionArea from '../components/InteractionArea'

import styles from '../Style.module.css'

function AppScreen(props) {
    const { setIsOpen } = props

    return (
        <section className={styles.appScreen}>
            <Header setIsOpen={setIsOpen} />
            <InteractionArea />
        </section>
    )
}

export default AppScreen
