// import { useEffect, useRef } from 'react'
import { useRef } from 'react'

import { mergeClasses, httpPost } from '../Utils'
import { useBuck, useBuckDispatch } from '../BuckContext'
import predefinedMessages from '../PredefinedMessages'
// import consumer from '../Cable'

import { ReactComponent as SendIcon } from '../icons/chat_send.svg'

import styles from '../Style.module.css'

function KnownConversationForm() {
    // const { conversation, configuration } = useBuck()
    const { conversation } = useBuck()
    const dispatch = useBuckDispatch()
    const inputRef = useRef(null)
    // let channel;

    const handleSubmit = event => {
        event.preventDefault()

        const message = inputRef.current.value

        dispatch({
            type: 'addMessage',
            message: {
                text: message,
                from: 'user',
                status: 'read'
            }
        })

        httpPost(`/lite/conversations/${conversation}`, { 
            message, 
            user: { 
                id: '1', 
                name: 'David', 
                locations: [
                    { id: '1', to_s: '9218 Balcones Club Dr, Austin, TX 78750, USA' },
                    { id: '2', to_s: '12215 Roxie Dr, Austin, TX 78729, USA' }
                ] 
            }, 
            channel: 'sms' 
        })
            .then(response => {
                dispatch({
                    type: 'addMessage',
                    message: {
                        text: response.data.message,
                        from: 'system',
                        status: 'read'
                    }
                })
            })

        inputRef.current.value = ''
        // channel.perform('speak', { message })
    }

    // useEffect(() => {
    //     channel = consumer.subscriptions.create({
    //         channel: 'WebChat::RoomChannel',
    //         access_key: configuration.access_key,
    //         conversation: conversation
    //     })

    //     return () => {
    //         consumer.disconnect()
    //     }
    // }, [])

    return (
        <form className={styles.messageForm} onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder={predefinedMessages.knownConversationForm.message}
                className={mergeClasses(styles.input, styles.messageFormInput)}
                autoComplete="off"
                ref={inputRef}
                required />

            <button
                type="submit"
                className={styles.messageFormSubmit}>
                <SendIcon width="22" height="22" />
            </button>
        </form>
    )
}

export default KnownConversationForm
