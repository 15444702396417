import { useState, useEffect } from 'react'
import Autocomplete from 'react-google-autocomplete'

import { useBuckDispatch, useBuck } from '../BuckContext'
import { mergeClasses, httpPost, setLocalStorage } from '../Utils'
import predefinedMessages from '../PredefinedMessages'

import styles from '../Style.module.css'

function UnknownConversationForm(props) {
    const { setStep } = props

    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [address, setAddress] = useState(null)
    const [unit, setUnit] = useState(null)

    const dispatch = useBuckDispatch()
    const { configuration } = useBuck()
    const { access_key } = configuration

    useEffect(() => {
        dispatch({
            type: 'addMessage',
            message: {
                id: 1,
                text: configuration.messages.customer_information_welcome_message,
                from: 'system',
                status: 'read'
            }
        })
    // eslint-disable-next-line
    }, [])

    const selectPlace = place => {
        const { address_components, geometry } = place

        const streetNumber = exposeFieldFromPlaceResult(address_components, 'street_number')
        const street = exposeFieldFromPlaceResult(address_components, 'route')
        const city = exposeFieldFromPlaceResult(address_components, 'locality')
        const state = exposeFieldFromPlaceResult(address_components, 'administrative_area_level_1')
        const country = exposeFieldFromPlaceResult(address_components, 'country')
        const zip = exposeFieldFromPlaceResult(address_components, 'postal_code')

        setAddress({
            street: `${streetNumber} ${street}`,
            latitude: geometry.location.lat(),
            longitude: geometry.location.lng(),
            city, state, country, zip
        })
    }

    const conversationCreated = response => {
        setLocalStorage('conversation', response.data.id)
        setStep('messaging')

        dispatch({
            type: 'addMessage',
            message: {
                id: 1,
                text: configuration.messages.conversation_create_message,
                from: 'system',
                status: 'read'
            }
        })
    }

    const conversationFailed = error => {}

    const handleSubmit = event => {
        event.preventDefault()

        if (name.length === 0 || email.length === 0 || phone.length === 0 || address === null) return;

        const conversation = {
            job_attributes: {
                customer_attributes: {
                    name,
                    contacts_attributes: [
                        { contact_type: 'email', value: email },
                        { contact_type: 'phone', value: phone }
                    ],
                    locations_attributes: [{ ...address, unit }]
                }
            }
        }

        // httpPost('/omnichannel/conversations', { conversation }, access_key)
        //     .then(conversationCreated)
        //     .catch(conversationFailed)
        conversationCreated({ data: { id: new Date().getTime() } })
    }

    const exposeFieldFromPlaceResult = (result, field) => result.find(info => info.types.includes(field))?.short_name

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder={predefinedMessages.unknownConversationForm.name}
                className={styles.input}
                onChange={e => setName(e.target.value)}
                autoComplete="name"
                required />

            <input
                type="email"
                placeholder={predefinedMessages.unknownConversationForm.email}
                className={styles.input}
                onChange={e => setEmail(e.target.value)}
                autoComplete="email"
                required />

            <input
                type="phone"
                placeholder={predefinedMessages.unknownConversationForm.phone}
                className={styles.input}
                onChange={e => setPhone(e.target.value)}
                autoComplete="tel"
                required />

            <Autocomplete
                apiKey={configuration.google_api_key}
                className={mergeClasses(styles.input, styles['span-2'])}
                autoComplete="off"
                required
                onPlaceSelected={selectPlace}
                options={{
                    componentRestrictions: { country: "us" },
                    types: [],
                    fields: [
                        "formatted_address",
                        "geometry.location",
                        "address_components"
                    ]
                }}
                language="en"
            />

            <input
                type="text"
                placeholder={predefinedMessages.unknownConversationForm.unit}
                className={mergeClasses(styles.input, styles['span-1'])}
                onChange={e => setUnit(e.target.value)}
                autoComplete="off"
                required />

            <input
                type="submit"
                value={predefinedMessages.unknownConversationForm.submit}
                className={mergeClasses(styles.input, styles.submit)} />
        </form>
    )
}

export default UnknownConversationForm
