import { useState } from 'react'

import { useBuck } from './BuckContext.js'
import ChatIconScreen from './screens/ChatIconScreen'
import AppScreen from './screens/AppScreen'

import styles from './Style.module.css'

function App() {
  const [isOpen, setIsOpen] = useState(false)
  const buck = useBuck()

  setAppTheme(buck.configuration.theme)

  return (
    <div className={styles.root}>
      {isOpen ? <AppScreen setIsOpen={setIsOpen} /> : <ChatIconScreen setIsOpen={setIsOpen} />}
    </div>
  )
}

function setAppTheme(theme) {
  const POSITIONS   = ['top', 'left', 'right', 'bottom']

  setCSSVariable('colors-primary', theme.colors.primary)
  setCSSVariable('colors-primary-dark', generateDark(theme.colors.primary))
  setCSSVariable('colors-primary-text', theme.colors.primary_text)

  setCSSVariable('colors-secondary', theme.colors.secondary)
  setCSSVariable('colors-secondary-dark', generateDark(theme.colors.secondary))
  setCSSVariable('colors-secondary-text', theme.colors.secondary_text)

  POSITIONS.forEach(position => setCSSVariable(`position-${position}`, theme.position[position]))

  setCSSVariable('width', theme.width)
  setCSSVariable('max-height', theme.max_height)
  setCSSVariable('background-color', theme.background_color)
}

const setCSSVariable = (variable, value) => document.documentElement.style.setProperty(`--buck-${variable}`, value)
const generateDark   = (color) => `#${(parseInt(color.replace('#', ''), 16) - parseInt('1D1D', 16)).toString(16).padStart(6, '0')}`


export default App
