import { createContext, useContext, useReducer } from 'react'

const BuckContext = createContext(null)

const BuckDispatchContext = createContext(null)

export function BuckProvider({ children, initialState }) {
  const [buck, dispatch] = useReducer(
    buckReducer,
    initialState
  )

  return (
    <BuckContext.Provider value={buck}>
      <BuckDispatchContext.Provider value={dispatch}>
        {children}
      </BuckDispatchContext.Provider>
    </BuckContext.Provider>
  )
}

export function useBuck() {
  return useContext(BuckContext)
}

export function useBuckDispatch() {
  return useContext(BuckDispatchContext)
}

function buckReducer(buck, action) {
  switch (action.type) {
    case 'addMessage': {
      const existMessage = buck.messages.find(message => message.id === action.message.id)
      if (existMessage !== undefined) return buck

      return {
        ...buck,
        messages: [
          ...buck.messages,
          {
            id: action.message.id || new Date().getTime(),
            text: action.message.text,
            from: action.message.from,
            status: action.message.status,
            send_at: action.message.send_at === undefined ? new Date() : action.message.send_at
          }
        ]
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}
