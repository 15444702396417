import { useState } from 'react'

import ListMessages from './ListMessages.js'
import ConversationInformationForm from '../forms/ConversationInformationForm.js'

import styles from '../Style.module.css'
import KnownConversationForm from '../forms/KnownConversationForm.js'

function InteractionArea() {
    const [step, setStep] = useState('messaging')

    return (
        <section className={styles.interactionArea}>
            <ListMessages />

            {decideToForm(step, setStep)}
        </section>
    )
}


function decideToForm(step, setStep) {
    switch (step) {
        case 'getConversationInformation':
            return (<ConversationInformationForm setStep={setStep} />)

        case 'messaging':
            return (<KnownConversationForm />)
        default:
            break
    }
}

export default InteractionArea
