export const unknownConversationForm = {
    name: 'Full Name',
    email: 'Email Address',
    phone: 'Phone Number',
    address: 'Address',
    unit: 'Unit',
    submit: 'Submit'
}

export const knownConversationForm = {
    message: 'Enter your message here'
}

const message = { unknownConversationForm, knownConversationForm }

export default message
