import styles from '../Style.module.css'

import { ReactComponent as ChatIcon} from '../icons/chat_icon.svg'

function ChatIconScreen(props) {
    const { setIsOpen } = props

    const handleOpen = event => {
        event.preventDefault()

        setIsOpen(true)
    }

    return (<ChatIcon className={styles.chatIcon} onClick={handleOpen} height={32} width={32} />)
}

export default ChatIconScreen
